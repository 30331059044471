@import url("https://fonts.googleapis.com/css?family=Montserrat");

body {
  margin: 0;
  padding: 0;
  color: #f4f4f4;
  background-color:#ef476f;
  font-family: "Montserrat", sans-serif;
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #f4f4f4;
}

h2 {
  padding: 0;
  margin: 0;
}

.App {
  width: 100%;
  max-width: 100vw;
}

