.home {
  .social {
    display: flex;
    justify-content: space-around;
    > a {
      transform: translateY(0px);
      transition: all 1s 1s;
      padding: 20px;
      margin: -10px;
      &:hover {
        animation: bounce 2s infinite;
        transition: transform 1s 1s;
      }
    }
    @keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-30px);
      }
      60% {
        transform: translateY(-15px);
      }
    }
  }
}
