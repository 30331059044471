@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  padding: 0;
  color: #f4f4f4;
  background-color:#ef476f;
  font-family: "Montserrat", sans-serif;
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #f4f4f4;
}

h2 {
  padding: 0;
  margin: 0;
}

.App {
  width: 100%;
  max-width: 100vw;
}


.banner {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%; }
  .banner button {
    padding: 5px 10px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer; }
  .banner.green {
    background-color: #06d6a0;
    color: #333; }
    .banner.green button {
      background-color: #06d6a0;
      color: #333;
      font-family: "Montserrat", sans-serif;
      border: 1px solid #333; }
    .banner.green a {
      color: #333; }
  .banner.yellow {
    background-color: #ffd166;
    color: #000000; }
    .banner.yellow button {
      background-color: #ffd166;
      color: #000000;
      font-family: "Montserrat", sans-serif;
      border: 1px solid #000000; }
    .banner.yellow a {
      color: #000000; }
  .banner.red {
    background-color: #ef476f;
    color: #fff; }
    .banner.red button {
      background-color: #ef476f;
      color: #fff;
      font-family: "Montserrat", sans-serif;
      border: 1px solid #fff; }
    .banner.red a {
      color: #fff; }
  .banner.blue {
    background-color: #118ab2;
    color: #fff; }
    .banner.blue button {
      background-color: #118ab2;
      color: #fff;
      font-family: "Montserrat", sans-serif;
      border: 1px solid #fff; }
    .banner.blue a {
      color: #fff; }
  .banner.white {
    background-color: #fff;
    color: #333; }
    .banner.white button {
      background-color: #fff;
      color: #333;
      font-family: "Montserrat", sans-serif;
      border: 1px solid #333; }
    .banner.white a {
      color: #333; }
  .banner .drop-transition {
    -webkit-transition: opacity 2s, -webkit-transform 2s;
    transition: opacity 2s, -webkit-transform 2s;
    transition: opacity 2s, transform 2s;
    transition: opacity 2s, transform 2s, -webkit-transform 2s;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  .banner .slide-transition {
    -webkit-transition: opacity 2s, -webkit-transform 2s;
    transition: opacity 2s, -webkit-transform 2s;
    transition: opacity 2s, transform 2s;
    transition: opacity 2s, transform 2s, -webkit-transform 2s;
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    opacity: 0; }
  .banner .banner-content {
    max-width: 1040px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column; }
    .banner .banner-content.fullscreen {
      max-width: none;
      max-width: initial; }
  .banner .banner-visible .drop-transition,
  .banner .banner-visible .slide-transition {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1; }
  .banner .banner-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .banner .banner-info section {
      white-space: pre-wrap; }

.home .bio {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1; }
  .home .bio .full-name {
    align-self: flex-start;
    font-size: 20px;
    margin-bottom: 20px; }
    .home .bio .full-name .last-name {
      color: #000000;
      align-self: flex-start;
      font-size: 40px; }
      .home .bio .full-name .last-name .raised {
        position: relative;
        z-index: 1; }
      .home .bio .full-name .last-name .text-highlight {
        top: -35px;
        left: 10px;
        width: 235px;
        height: 40px;
        position: relative;
        background-color: #ffd166;
        margin-bottom: -20px; }
  .home .bio .picture-row {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end; }
    .home .bio .picture-row .bio-picture > img {
      width: 300px;
      height: 300px;
      border-radius: 150px;
      border: 5px solid white;
      box-sizing: border-box;
      opacity: 0;
      -webkit-transition: opacity 1s;
      transition: opacity 1s; }
    .home .bio .picture-row .bio-picture > img.loaded {
      opacity: 1; }
    .home .bio .picture-row .bio-picture > .bio-description {
      text-align: right;
      position: relative;
      top: -80px;
      -webkit-transform: rotateZ(-20deg);
              transform: rotateZ(-20deg);
      font-size: 30px;
      margin-bottom: -60px;
      -webkit-transition: font-size 1s, margin-left 1s, top 1s, -webkit-transform 1s;
      transition: font-size 1s, margin-left 1s, top 1s, -webkit-transform 1s;
      transition: transform 1s, font-size 1s, margin-left 1s, top 1s;
      transition: transform 1s, font-size 1s, margin-left 1s, top 1s, -webkit-transform 1s; }
      .home .bio .picture-row .bio-picture > .bio-description .role {
        background-color: #ffd166;
        color: #000000; }
      .home .bio .picture-row .bio-picture > .bio-description .location {
        background-color: #000000;
        color: #fff; }

@media only screen and (orientation: portrait) and (max-height: 710px) {
  .App > :first-child {
    height: 100vh; } }

.home .social {
  display: flex;
  justify-content: space-around; }
  .home .social > a {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-transition: all 1s 1s;
    transition: all 1s 1s;
    padding: 20px;
    margin: -10px; }
    .home .social > a:hover {
      -webkit-animation: bounce 2s infinite;
              animation: bounce 2s infinite;
      -webkit-transition: -webkit-transform 1s 1s;
      transition: -webkit-transform 1s 1s;
      transition: transform 1s 1s;
      transition: transform 1s 1s, -webkit-transform 1s 1s; }

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); } }

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); } }

.home .spotify-key-signatures {
  display: flex;
  justify-content: center; }

@-webkit-keyframes vinyl {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(359deg);
            transform: rotateZ(359deg); } }

@keyframes vinyl {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(359deg);
            transform: rotateZ(359deg); } }
  .home .spotify-key-signatures .spotify-record {
    height: 150px;
    width: 150px;
    margin: 40px;
    -webkit-animation: vinyl 4s infinite;
            animation: vinyl 4s infinite; }

.home .razer-animation {
  display: flex;
  justify-content: center; }
  .home .razer-animation .razer-board {
    display: flex;
    max-width: 500px;
    align-items: flex-end;
    align-self: center;
    flex-wrap: wrap;
    margin: 20px; }
    .home .razer-animation .razer-board > .keys {
      display: flex;
      flex-grow: 5;
      align-items: flex-end; }
      .home .razer-animation .razer-board > .keys .key {
        width: 40px;
        height: 40px;
        flex-grow: 1;
        background-color: #1f363d;
        color: #f6f9f4;
        margin: 4px;
        padding-left: 10px;
        border-radius: 10px;
        border-bottom: 5px solid #111e22; }
        .home .razer-animation .razer-board > .keys .key.pressed {
          border-bottom: 0; }
        .home .razer-animation .razer-board > .keys .key.key0 {
          color: #fea3aa;
          text-shadow: 0 0 2px #fea3aa; }
        .home .razer-animation .razer-board > .keys .key.key1 {
          color: #f8b88b;
          text-shadow: 0 0 2px #f8b88b; }
        .home .razer-animation .razer-board > .keys .key.key2 {
          color: #faf884;
          text-shadow: 0 0 2px #faf884; }
        .home .razer-animation .razer-board > .keys .key.key3 {
          color: #baed91;
          text-shadow: 0 0 2px #baed91; }
        .home .razer-animation .razer-board > .keys .key.key4 {
          color: #b2cefe;
          text-shadow: 0 0 2px #b2cefe; }

.home .take-note .animated_box {
  min-width: 300px;
  overflow: hidden; }
  .home .take-note .animated_box #animated_note {
    position: relative;
    -webkit-animation: spin 15s linear infinite;
    animation: spin 15s linear infinite;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    left: 50px;
    height: 200px;
    width: 200px; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotateY(0deg) rotateX(0deg) rotateZ(30deg); }
  100% {
    -webkit-transform: rotateY(360deg) rotateX(0deg) rotateZ(30deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotateY(0deg) rotateX(0deg) rotateZ(30deg); }
  100% {
    -webkit-transform: rotateY(360deg) rotateX(0deg) rotateZ(30deg); } }
    .home .take-note .animated_box #animated_note .staves {
      position: absolute;
      display: flex;
      flex-direction: column;
      height: 100px;
      justify-content: space-between;
      top: 55px;
      left: 5px; }
      .home .take-note .animated_box #animated_note .staves > div {
        width: 200px;
        height: 5px;
        border-radius: 20px;
        background-color: #118ab2;
        -webkit-transform: rotateY(40deg) rotateX(45deg);
                transform: rotateY(40deg) rotateX(45deg); }
    .home .take-note .animated_box #animated_note .box {
      height: 20px;
      width: 20px;
      background-color: #118ab2;
      position: absolute;
      border-radius: 0px; }
      .home .take-note .animated_box #animated_note .box.stem {
        width: 5px;
        height: 60px;
        top: 40px;
        left: 100px; }
        .home .take-note .animated_box #animated_note .box.stem.front {
          -webkit-transform: rotateY(0deg) translateZ(10px) translateX(0px);
                  transform: rotateY(0deg) translateZ(10px) translateX(0px); }
        .home .take-note .animated_box #animated_note .box.stem.back {
          -webkit-transform: rotateY(-180deg) translateZ(-5px) translateX(0px);
                  transform: rotateY(-180deg) translateZ(-5px) translateX(0px); }
        .home .take-note .animated_box #animated_note .box.stem.left {
          -webkit-transform: rotateY(-90deg) translateZ(2.5px) translateX(7.5px);
                  transform: rotateY(-90deg) translateZ(2.5px) translateX(7.5px); }
        .home .take-note .animated_box #animated_note .box.stem.right {
          -webkit-transform: rotateY(90deg) translateZ(2.5px) translateX(-7.5px);
                  transform: rotateY(90deg) translateZ(2.5px) translateX(-7.5px); }
      .home .take-note .animated_box #animated_note .box.note_body {
        top: 100px;
        left: 100px; }
        .home .take-note .animated_box #animated_note .box.note_body.front {
          -webkit-transform: rotateY(0deg) translateZ(10px);
                  transform: rotateY(0deg) translateZ(10px); }
        .home .take-note .animated_box #animated_note .box.note_body.back {
          -webkit-transform: rotateY(-180deg) translateZ(10px);
                  transform: rotateY(-180deg) translateZ(10px); }
        .home .take-note .animated_box #animated_note .box.note_body.left {
          -webkit-transform: rotateY(-90deg) translateZ(10px);
                  transform: rotateY(-90deg) translateZ(10px); }
        .home .take-note .animated_box #animated_note .box.note_body.right {
          -webkit-transform: rotateY(90deg) translateZ(10px);
                  transform: rotateY(90deg) translateZ(10px); }
        .home .take-note .animated_box #animated_note .box.note_body.top {
          -webkit-transform: rotateX(90deg) translateZ(10px);
                  transform: rotateX(90deg) translateZ(10px); }
        .home .take-note .animated_box #animated_note .box.note_body.bottom {
          -webkit-transform: rotateX(-90deg) translateZ(10px);
                  transform: rotateX(-90deg) translateZ(10px); }
      .home .take-note .animated_box #animated_note .box.front, .home .take-note .animated_box #animated_note .box.left, .home .take-note .animated_box #animated_note .box.top {
        background-color: #06d6a0; }

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .flex.grow {
    flex-grow: 1;
    flex-basis: 1px; }

.flex.column {
  flex-direction: column; }

.flex.center {
  justify-content: center; }

.flex.spaceAround {
  justify-content: space-around; }

.flex.wrap {
  flex-wrap: wrap; }

.wheel-frame {
  width: 200vh;
  height: 200vh;
  position: relative;
  top: -100vh;
  left: -75vh; }
  .wheel-frame > svg {
    flex-grow: 1; }

.yay {
  position: absolute;
  background-color: #118ab2;
  border-radius: 5vw;
  opacity: 90%;
  height: 10px;
  width: 80vw;
  height: 30vh;
  top: 30vh;
  left: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 40px; }

