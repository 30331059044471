.wheel-frame {
  width: 200vh;
  height: 200vh;
  position: relative;
  top: -100vh;
  left: -75vh;
  > svg {
    flex-grow: 1;
  }
}

.yay {
  position: absolute;
  background-color: #118ab2;
  border-radius: 5vw;
  opacity: 90%;
  height: 10px;
  width: 80vw;
  height: 30vh;
  top: 30vh;
  left: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 40px;
}
