.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.grow{
    flex-grow:1;
    flex-basis:1px;
  }
}

.flex.column {
  flex-direction: column;
}

.flex.center {
  justify-content: center;
}

.flex.spaceAround {
  justify-content: space-around;
}

.flex.wrap {
  flex-wrap: wrap;
}
